@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  @page {
    size: landscape;
    margin: 0;
  }

  body {
    background: white !important;
    color: black !important;
    margin: 0 !important;
    min-height: 100vh !important;
  }

  .print\:w-full {
    width: 100% !important;
  }

  .print\:h-auto {
    height: 100vh !important;
  }

  svg {
    width: 100% !important;
    height: 100% !important;
  }

  svg text {
    fill: black !important;
  }

  svg line, svg path {
    stroke: #374151 !important;
    stroke-opacity: 1 !important;
  }
}

/* Add this to ensure the container grows */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.min-h-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
}